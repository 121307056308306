var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "col-md-3 animate__animated animate__fadeInUp animate__slower",
          },
          [
            _c(
              "div",
              {
                staticClass: "card text-center",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.clickOnFAQGuide()
                  },
                },
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("@/assets/images/guide1.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "card-text mt-4" }, [
                    _c("h5", [_vm._v(_vm._s(_vm.$t("faqs.faq")))]),
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("faqs.faqText")) + " "),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-md-3 animate__animated animate__fadeInUp animate__slower",
          },
          [
            _c(
              "div",
              {
                staticClass: "card text-center",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.clickOnUploadGuide()
                  },
                },
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("@/assets/images/guide2.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "card-text mt-4" }, [
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$t("faqs.guideForUploadProcess"))),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("faqs.guideForUploadProcessText")) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-md-3 animate__animated animate__fadeInUp animate__slower",
          },
          [
            _c(
              "div",
              {
                staticClass: "card text-center",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.clickOnProjectiveQuestionGuide()
                  },
                },
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("@/assets/images/guide3.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "card-text mt-4" }, [
                    _c("h5", [
                      _vm._v(
                        _vm._s(_vm.$t("faqs.guideForProjectiveQuestions"))
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("faqs.guideForProjectiveQuestionsText")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "col-md-3 animate__animated animate__fadeInUp animate__slower",
          },
          [
            _c(
              "div",
              {
                staticClass: "card text-center",
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.clickOnAnalysisGuide()
                  },
                },
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("@/assets/images/guide4.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "card-text mt-4" }, [
                    _c("h5", [
                      _vm._v(_vm._s(_vm.$t("faqs.guideForRWAAnalysis"))),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("faqs.guideForRWAAnalysisText")) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }